<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #users="{ value }">
      <div>
        {{ appendZeroIfNeeded(value.length) || "-" }}
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "GroupList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    appendZeroIfNeeded(count) {
      if (count === 0) {
        return 0;
      }
      return count < 10 ? `0${count}` : count;
    },

    handleSelect(groupId) {
      this.$emit("select", groupId);
    },

    handleEdit(groupId) {
      this.$emit("edit", groupId);
    },

    handleDelete(groupId) {
      this.$emit("delete", groupId);
    },

    handleRestore(groupId) {
      this.$emit("restore", groupId);
    },

    handleMore(groupId) {
      this.$emit("more", groupId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
