<template>
  <ItemDetails
    :value="value"
    :history="history"
    :overview="overview"
    @edit="handleEdit"
    @input="handleInput"
    @delete="handleDelete"
  >
    <template #title>Group Details</template>

    <template #users="{ value: users }">
      <div v-if="users && users.length">
        <ol>
          <li v-for="user in processGroupUsers(users)" :key="user.id">
            {{ user.email }}
          </li>
        </ol>

        <div
          v-if="users.length > 5"
          class="show-all"
          @click="showAllGroupUsers = !showAllGroupUsers"
        >
          {{
            showAllGroupUsers
              ? "show less users"
              : "show all " + users.length + " users"
          }}
        </div>
      </div>

      <div v-else>-</div>
    </template>
  </ItemDetails>
</template>

<script>
import { group } from "@/api/factory.js";
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "GroupDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAllGroupUsers: false,
      isLoading: false,
      history: [],
    };
  },

  computed: {
    overview() {
      return this.columns.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        value: this.group[column.name],
      }));
    },
  },

  watch: {
    group: {
      immediate: true,
      deep: true,
      handler() {
        if (this.group.id) {
          this.getGroupHistory();
        }
      },
    },
  },

  methods: {
    processGroupUsers(users) {
      if (this.showAllGroupUsers) {
        return users;
      }

      return users.slice(0, 5);
    },

    handleInput(value) {
      this.$emit("input", value);
    },

    handleEdit() {
      this.$emit("input", false);
      this.$emit("edit", this.group.id);
    },

    handleDelete() {
      this.$emit("input", false);
      this.$emit("delete", this.group.id);
    },

    /* api functions */

    async getGroupHistory() {
      this.isLoading = true;

      const { error, payload } = await group.getGroupHistory(this.group.id);

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.history = payload;
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 16px;
  margin: 0px;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.show-all {
  @extend .text-xs;
  color: var(--secondary);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 4px;
}
</style>
