<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #users="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div>
          {{ appendZeroIfNeeded(value.length) || "-" }}
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "GroupGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    appendZeroIfNeeded(count) {
      if (count === 0) {
        return 0;
      }
      return count < 10 ? `0${count}` : count;
    },

    handleSelect(groupId) {
      this.$emit("select", groupId);
    },

    handleEdit(groupId) {
      this.$emit("edit", groupId);
    },

    handleDelete(groupId) {
      this.$emit("delete", groupId);
    },

    handleRestore(groupId) {
      this.$emit("restore", groupId);
    },

    handleMore(groupId) {
      this.$emit("more", groupId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
